import { http } from '@/core/api';

class DocumentApi {
  async getDocument(id) {
    try {
      const { data } = await http.get(`/api/documents/${id}`);
      return data;
    } catch {
      return null;
    }
  }
}

export { DocumentApi };
