<template>
  <div class="ui-detail-list-item">
    <div class="ui-detail-list-item--container">
      <div v-if="!hideNumber" class="ui-detail-list-item--number">
        {{ number }}
      </div>
      <div
        class="ui-detail-list-item--label"
        :class="{ 'no-number': hideNumber }"
      >
        {{ label }}
      </div>
      <div class="ui-detail-list-item--text self-align-end">
        <slot v-if="$slots.default || $scopedSlots.default" />
        <span v-else-if="text">
          {{ text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDetailListItem',
  props: {
    number: { type: [String, Number], default: '' },
    label: { type: [String, Number], default: '' },
    text: { type: [String, Number], default: '' },
    hideNumber: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
$gray: #fafafa;
$gray-darker: #f2f2f2;
$border-radius: 0.5rem;
$padding: 0.8rem;
$number-font-size: 1.5rem;

.ui-detail-list-item {
  &--container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    min-height: $padding + $number-font-size;
  }

  &--number {
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    background-color: #ff7058;

    min-width: 4rem;
    font-size: $number-font-size;
    border-radius: $border-radius 0 0 $border-radius;
  }

  &--label {
    display: flex;
    align-items: center;

    background-color: $gray;
    padding: $padding;
    padding-left: $padding * 2;
    padding-right: $padding * 2;
    width: 100%;

    &:first-child {
      border-radius: $border-radius 0 0 $border-radius;
    }
  }

  &--text {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 10rem;
    padding: $padding;
    margin-left: 0.5rem;
    background-color: $gray-darker;
    border-radius: 0 $border-radius $border-radius 0;
  }
}
</style>
