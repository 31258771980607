<template>
  <div v-if="studentSieId && peiId">
    <PageHeader
      :page="{
        icon: 'fas fa-history',
        title: 'Cuestionario de PEI en Controversia',
        routes: [
          'Inicio',
          'Historial del PEI',
          'Cuestionario de PEI en Controversia',
        ],
      }"
    >
      <template #customActions>
        <b-row align-h="end" align-v="center" class="h-100">
          <b-button
            class="mx-2"
            variant="outline-success"
            @click="$router.push({ name: 'student-pei-history' })"
          >
            <i class="fas fa-arrow-circle-left fa-fw" />
            Regresar
          </b-button>
        </b-row>
      </template>
    </PageHeader>

    <StudentPeiControversy
      :has-controversy.sync="hasControversy"
      :pei-id="peiId"
      :student-sie-id="studentSieId"
    />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import StudentPeiControversy from '@/components/StudentPeiControversy';

export default {
  name: 'PeiControversy',
  components: { PageHeader, StudentPeiControversy },
  props: {
    studentSieId: { type: [String, Number], default: null },
    peiId: { type: [String, Number], default: null },
  },
  data: () => ({ hasControversy: null }),
  watch: {
    hasControversy(value) {
      if (value === false) {
        this.$router.push({ name: 'student-pei-history' });
      }
    },
  },
};
</script>
