var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.studentSieId && _vm.peiId)?_c('div',[_c('PageHeader',{attrs:{"page":{
      icon: 'fas fa-history',
      title: 'Cuestionario de PEI en Controversia',
      routes: [
        'Inicio',
        'Historial del PEI',
        'Cuestionario de PEI en Controversia' ],
    }},scopedSlots:_vm._u([{key:"customActions",fn:function(){return [_c('b-row',{staticClass:"h-100",attrs:{"align-h":"end","align-v":"center"}},[_c('b-button',{staticClass:"mx-2",attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.$router.push({ name: 'student-pei-history' })}}},[_c('i',{staticClass:"fas fa-arrow-circle-left fa-fw"}),_vm._v(" Regresar ")])],1)]},proxy:true}],null,false,3383506485)}),_c('StudentPeiControversy',{attrs:{"has-controversy":_vm.hasControversy,"pei-id":_vm.peiId,"student-sie-id":_vm.studentSieId},on:{"update:hasControversy":function($event){_vm.hasControversy=$event},"update:has-controversy":function($event){_vm.hasControversy=$event}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }