<template>
  <div v-if="studentSieId && peiId && hasControversy_local">
    <MatchMedia v-slot="{ mobile }">
      <SectionTitle has-line title="Partes del PEI que están en controversia" />

      <b-card body-class="pt-0" class="border-0" :no-body="mobile">
        <b-row class="mb-4">
          <template v-for="(item, i) in controversy.peiControversySections">
            <b-col :key="i" :class="{ 'mt-2': i > 0 }" cols="12">
              <UiDetailListItem
                :label="item.sectionDescription"
                :number="item.sectionName"
                :text="item.sectionControversyAnswer ? 'SI' : 'NO'"
              />

              <b-card
                v-if="item.viewSectionControversyComment"
                body-bg-variant="gray"
                class="border-0 mt-2"
              >
                <template
                  v-if="item.servicesList && item.servicesList.length > 0"
                >
                  <p class="text-muted font-weight-light">
                    Si la controversia en la parte {{ item.sectionName }} es con
                    los Servicios Relacionados, seleccione cuales:
                  </p>

                  <p class="d-flex flex-wrap align-items-center">
                    <template v-for="(service, j) in item.servicesList">
                      <UiBadge
                        :key="j"
                        :class="{ 'ml-2': j > 0 }"
                        :text="service.description"
                      />
                    </template>
                  </p>
                </template>

                <UiDetailDisplay
                  label="Razón:"
                  :text="item.sectionControversyComment"
                />
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-card>

      <SectionTitle has-line title="Información Adicional" />

      <b-card body-class="pt-0" class="border-0 mb-4" :no-body="mobile">
        <b-row>
          <b-col cols="12">
            <UiDetailListItem
              hide-number
              label="¿El PEI fue aprobado parcialmente?"
              :text="controversy.peiPartiallyApprovedAnswer ? 'SI' : 'NO'"
            />
          </b-col>

          <b-col class="mt-2" cols="12">
            <UiDetailListItem
              hide-number
              label="El PEI aprobado parcialmente, ¿requería servicios relacionados?"
              :text="controversy.relatedServiceRequiredAnswer ? 'SI' : 'NO'"
            />
          </b-col>

          <b-col class="mt-2" cols="12">
            <UiDetailListItem
              hide-number
              label="El PEI aprobado parcialmente, ¿continuó recibiendo servicios relacionados?"
              :text="controversy.continuedRelatedServiceAnswer ? 'SI' : 'NO'"
            />
          </b-col>
        </b-row>
      </b-card>

      <SectionTitle has-line title="I. Documentos del PEI" />

      <b-card
        v-for="(item, i) in controversy.documents"
        :key="i"
        body-bg-variant="gray"
        class="border-0 mb-4"
      >
        <b-row align-v="center">
          <b-col cols="12" md>
            <UiDetailDisplay label="Fecha:" :text="item.documentDate" />
          </b-col>
          <b-col class="mt-4 mt-md-0" cols="12" md>
            <UiDetailDisplay
              label="Tipo de Documento:"
              :text="item.itemAttachmentTypeDesc"
            />
          </b-col>
          <b-col class="mt-4 mt-md-0" cols="12" md>
            <UiDetailDisplay label="Título:" :text="item.fileName" />
          </b-col>
          <b-col class="mt-4 mt-md-0" cols="12" md>
            <UiDetailDisplay label="Comentario:" :text="item.comment" />
          </b-col>
          <b-col class="mt-4 mt-md-0 text-md-right" cols="12" md>
            <b-button
              :block="mobile"
              :size="mobile ? 'sm' : 'md'"
              variant="outline-success"
              @click="downloadPeiDocument(item.itemAttachmentId)"
            >
              <i class="fas fa-file-download" :class="{ 'fa-2x': mobile }" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <div class="pt-2" />

      <PageHeader
        class="header-without-breadcrumb"
        :page="{
          title: 'Términos y Responsabilidades',
          icon: 'far fa-check-circle',
          routes: [],
        }"
      />

      <b-card footer-bg-variant="gray">
        <span class="font-weight-bolder">
          Certifico por este medio, que este referido podrá encontrarse en el
          expediente físico del estudiante firmado por todas las partes
          involucradas. (0502)
        </span>
        <template #footer>
          <b-row align-v="center">
            <b-col class="border-right" cols="2" md="1">
              <div class="text-center">
                <i class="far fa-check-square fa-2x bg-white" />
              </div>
            </b-col>
            <b-col><span>Acepto los términos y responsabilidades</span></b-col>
          </b-row>
        </template>
      </b-card>
    </MatchMedia>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import PageHeader from 'PageHeader';
import UiDetailDisplay from '@/components/ui/UiDetailDisplay';
import UiDetailListItem from '@/components/ui/UiDetailListItem';
import UiBadge from '@/components/ui/UiBadge';
import { MatchMedia } from 'vue-component-media-queries';
import { PeiApi } from '@/api/pei.api';
import { DocumentApi } from '@/api/document.api';
import { downloadBlobToFile } from '@/utils/blob';

const peiApi = new PeiApi();
const documentApi = new DocumentApi();

export default {
  name: 'StudentPeiControversy',
  components: {
    SectionTitle,
    PageHeader,
    UiDetailDisplay,
    UiDetailListItem,
    UiBadge,
    MatchMedia,
  },
  props: {
    studentSieId: { type: [String, Number], default: null },
    peiId: { type: [String, Number], default: null },
    hasControversy: { type: Boolean, default: null },
  },
  data: () => ({
    hasControversy_local: false,
    controversy: null,
  }),
  computed: {
    hasControversy_sync: {
      get() {
        return this.hasControversy_local;
      },
      set(value) {
        this.hasControversy_local = value;
        this.$emit('update:hasControversy', value);
      },
    },
  },
  methods: {
    async getControversy() {
      const data = await peiApi.getControversy(this.peiId);
      if (!data?.peiControversyForm?.peiControversySections?.length) {
        return false;
      }
      const { documents, peiControversyForm } = data;
      this.controversy = { ...peiControversyForm, documents };
      return true;
    },
    async downloadPeiDocument(peiId) {
      const data = await documentApi.getDocument(peiId);
      if (data) {
        downloadBlobToFile(data);
      }
    },
  },
  async created() {
    const hasControversy = await this.getControversy();
    this.hasControversy_sync = hasControversy;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .header-without-breadcrumb {
  margin-bottom: 1rem;

  &.row {
    .col-12 {
      .row.mt-2.controls-wrap {
        display: none;
      }

      .dropdown-divider {
        display: none;
      }
    }
  }
}
</style>
